import { CallToAction } from 'components/CallToAction'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'
import React from 'react'
import { prepareData } from 'utils/googleData'

export default function Hero(props: {
    title: string
    subtitle: string
    heroDescription: string
    image: {
        image: ImageDataLike
        width: number | string
        height: number | string
    }
    svgImage: string
    cta: {
        title: string
        url: string
    }
    cta2: {
        title: string
        url: string
    }
}) {
    const { enable, title, subtitle, description, cta, cta2, svgImage, gatsbyImage, imageStyles } = prepareData({
        props,
    })
    if (enable?.toLowerCase?.() === 'false' && !process.env.RENDER_DISABLED_COMPONENTS) {
        return <></>
    }
    return (
        <>
            <div>
                <h1
                    id="overview"
                    className="mb-0 mt-8 text-center text-3xl dark:rounded dark:bg-primary dark:bg-opacity-50 dark:p-4 md:mt-14 lg:text-6xl 2xl:text-7xl"
                >
                    <span className="text-red">{title}</span>
                </h1>
                <p className="mt-4 text-center text-lg  font-semibold" dangerouslySetInnerHTML={{ __html: subtitle }} />
                {cta2 && cta && (
                    <div className="flex flex-col justify-center space-y-3 md:flex-row md:items-center md:space-y-0 md:space-x-4">
                        <CallToAction to={cta.url} size="sm" className="md:min-w-[200px]">
                            {cta.title}
                        </CallToAction>
                        <CallToAction type="secondary" to={cta2.url} size="sm" className="md:min-w-[200px]">
                            {cta2.title}
                        </CallToAction>
                    </div>
                )}
            </div>
            {gatsbyImage && (
                //         <div
                //         className="grid grid-cols-1 md:grid-cols-3 space-y-6 md:space-y-0 md:space-x-16 items-center py-8 border-b border-gray-accent-light border-dashed last:border-b-0 max-w-5xl mx-auto px-5 box-content"
                //     >
                // {description && <p className="my-0 mt-1 text-lg  font-semibold">{description}</p>}
                <div
                    style={imageStyles}
                    //col-span-2
                    className="leading-0 relative mx-auto mt-8 -mb-12 text-center after:absolute after:bottom-12 after:left-0 after:h-36 after:w-full after:bg-gradient-to-b after:from-tan/0 after:via-tan/60 after:to-tan/100 after:content-['']"
                >
                    <GatsbyImage alt={title} image={gatsbyImage} className="rounded-md shadow-xl" />
                </div>

                // </div>
            )}
            {svgImage && !gatsbyImage && (
                <div
                    style={imageStyles}
                    //col-span-2
                    className="leading-0 relative mx-auto mt-8 -mb-12 text-center after:absolute after:bottom-12 after:left-0 after:h-36 after:w-full after:bg-gradient-to-b after:from-tan/0 after:via-tan/60 after:to-tan/100 after:content-['']"
                >
                    <img alt={title} className="rounded-md shadow-xl" src={svgImage} />
                </div>
            )}
        </>
    )
}
